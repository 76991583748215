import React, {useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {parseVector} from "../../lib/cvss";
import _ from "lodash";
import {Form, Modal, Tag, Typography} from "antd";
import {calculateAggregate} from "../../common/datagrid";
import {CVSSCalculator} from "../elements/CVSSCalculator";
import {useSchema} from "../../contexts/schema";

const {Text, Link} = Typography;

const RATINGS = [
    {shortTitle: 'N/A', title: 'Not Available', value: 0, color: '#ccc'},
    {shortTitle: 'VL', title: 'Very Low', value: 1, color: '#4682B4'},
    {shortTitle: 'L', title: 'Low', value: 2, color: '#228B22'},
    {shortTitle: 'M', title: 'Medium', value: 3, color: '#FF8C00'},
    {shortTitle: 'H', title: 'High', value: 4, color: '#B22222'},
    {shortTitle: 'VH', title: 'Very High', value: 5, color: '#BA55D3'}
]

const CVSSModalField = ({value, onChange, onCancel, visible = false, showEnvironmental = false}) => {
    const [form] = Form.useForm();
    return (
        <Modal
            visible={visible}
            width={'100%'}
            style={{top: 20}}
            onOk={() => {
                onChange(form.getFieldsValue(['scores', 'vector']));
            }}
            onCancel={onCancel}
        >
            <CVSSCalculator form={form} showEnvironmental={showEnvironmental} values={value} />
        </Modal>
    );
}

const CVSSRating = ({onChange, value, template = false, form, field}) => {
    const [modalVisible, setModalVisible] = useState(false);
    const {t} = useTranslation('vulnerability');
    template = useSchema().template;

    const transformVectorToReasons = (vector, useTemporal, useEnvironmental) => {
        if (vector !== '') {
            vector = parseVector(vector, !useEnvironmental);

            let osReasons = [];
            let pocReasons = [];

            if (useEnvironmental) {
                const {MAV, MAC, MPR, MUI, MS, MC, MI, MA, CR, IR, AR, E, RL, RC} = vector;

                const updateArray = (metric, values, a) => {
                    _.forEach(values, (v, k) => {
                        let reason;
                        if (_.startsWith(k, 'M')) {
                            const ok = k.replace('M', '');
                            reason = t(
                                [
                                    `metrics.${metric}.reasoning.${k}.${v}`,
                                    `metrics.${metric}.reasoning.${ok}.${vector[ok]}`
                                ],
                                ''
                            );
                        } else {
                            reason = t(`metrics.${metric}.reasoning.${k}.${v}`, '');
                        }

                        if (!_.isEmpty(reason))
                            a.push(`* ${reason}`);
                    });
                }

                updateArray('outcomeSeverity', {MC, MI, MA, MS, CR, IR, AR, RL}, osReasons);
                updateArray('probabilityOfCompromise', {MAV, MAC, MPR, MUI, E, RC}, pocReasons);
            } else {
                const {AV, AC, PR, UI, S, C, I, A, E, RL, RC} = vector;

                const updateArray = (metric, values, a) => {
                    _.forEach(values, (v, k) => {
                        const reason = t(`metrics.${metric}.reasoning.${k}.${v}`, '');
                        if (!_.isEmpty(reason))
                            a.push(`* ${reason}`);
                    });
                }

                updateArray('outcomeSeverity', {C, I, A, S, RL}, osReasons);
                updateArray('probabilityOfCompromise', {AV, AC, PR, UI, E, RC}, pocReasons);
            }

            return {
                outcomeSeverityReasoning: osReasons.join('\n'),
                probabilityOfCompromiseReasoning: pocReasons.join('\n')
            };
        }
    }

    const handleCVSSScores = ({scores, vector}) => {
        let adjustment = 0;
        let outcomeSeverity;
        let probabilityOfCompromise;
        let impact;
        let exploitability;

        const temporal = scores?.temporal;
        const environmental = scores?.environmental ?? {environmental: 0, impact: 0};
        const base = scores?.base ?? {impact: 0, exploitability: 0, base: 0};

        if (template) {
            if (temporal > 0) {
                adjustment = temporal - base.base;
            }
            impact = base.impact;
            exploitability = base.exploitability;
        } else {
            if (environmental.impact > 0) {
                impact = environmental.impact;
                exploitability = environmental.environmental - impact;
                adjustment = environmental.environmental - base.base;
            } else if (temporal > 0) {
                adjustment = temporal - base.base;
                impact = base.impact;
                exploitability = base.exploitability;
            } else {
                impact = base.impact;
                exploitability = base.exploitability;
            }
        }

        outcomeSeverity = Math.round((impact + (adjustment * 2 / 3)) / 6 * 3);
        probabilityOfCompromise = Math.round((exploitability + (adjustment / 3)) / 4 * 3);

        outcomeSeverity = Math.min(Math.max(1, outcomeSeverity), 3) || 1;
        probabilityOfCompromise = Math.min(Math.max(1, probabilityOfCompromise), 3) || 1;

        const reasons = transformVectorToReasons(
            vector,
            (temporal || 0) > 0,
            (environmental.environmental || 0) > 0
        );
        form.setFieldsValue({probabilityOfCompromise, outcomeSeverity, ...reasons});
        setModalVisible(false);
        return vector;
    }

    return (<>
        <Form.Item name={`${field.id}$cvssVector`} getValueFromEvent={handleCVSSScores} noStyle>
            <CVSSModalField visible={modalVisible} showEnvironmental={!template} onCancel={() => setModalVisible(false)} />
        </Form.Item>
        <Text
            strong
            style={{
                color: RATINGS[value].color,
                borderRadius: 2,
                backgroundColor: `${RATINGS[value].color}20`,
                padding: 4,
                display: 'block',
                textAlign: 'center',
                border: '1px solid currentcolor'
            }}
        >
            {RATINGS[value].title} (<Link onClick={() => setModalVisible(true)}>CVSS</Link>)
        </Text>
        <Form.Item shouldUpdate noStyle>
            {() => {
                // runs every time a form field changes (sort of like a form.onChange, if that existed)
                const next = calculateAggregate(form.getFieldsValue());
                if (next !== value) onChange(next);
                return null;
            }}
        </Form.Item>
    </>)
}

const AggregateRating = ({value, compact = false}) => <Tag color={RATINGS[value].color}>{compact ? RATINGS[value].shortTitle : RATINGS[value].title}</Tag>;

export default {
    Editor: CVSSRating,
    Viewer: AggregateRating,
    defaultValue: 0,
    stringify: async (value) => RATINGS[value].title,
    afterGet: (doc, field) => doc[field.id] = calculateAggregate(doc),
    beforePut: (doc, field) => doc[field.id] = calculateAggregate(doc),
};
