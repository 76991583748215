import React, {useEffect, useRef} from "react";
import {Button, Typography, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import {useDB} from "../../contexts/database";

const ListUpload = ({value, onChange, form, ...props}) => {
    const db = useDB();
    const docId = form.getFieldValue('_id');

    const urls = useRef([]);
    useEffect(() => () => {
        urls.current.map(URL.revokeObjectURL);
    }, []);

    const handleSelect = async ({onSuccess, onError, file}) => {
        file.url = URL.createObjectURL(file);
        urls.current.push(file.url);

        const rev = form.getFieldValue('_rev');

        try {
            let res = await db.putAttachment(docId, file.uid, rev, file, file.type);
            form.setFieldsValue({
                _rev: res.rev,
                isNew: ''
            });
            onSuccess();
        } catch(e) {
            return onError(e)
        }
    }

    // ant design calls handleChange(firstFile) on first upload
    // then handleChange([first, second]) etc on subsequent
    const handleChange = (e) => {
        let fileList;
        if (Array.isArray(e)) fileList = e;
        else fileList = e.fileList;
        onChange(fileList);
    }

    const handlePreview = async (file) => {
        if (file.url) {
            window.open(file.url);
            return;
        }
        const blob = await db.getAttachment(file.doc, file.uid);
        const f = new File([blob], file.name);
        const url = URL.createObjectURL(f);
        window.open(url);
        urls.current.push(url);
    }

    return (
        <Upload
            customRequest={handleSelect}
            onChange={handleChange}
            onPreview={handlePreview}
            fileList={value}
            {...props}
        >
            <Button icon={<UploadOutlined />}>Upload</Button>
        </Upload>
    )
}

const Viewer = ({value}) => {
    const db = useDB();

    const downloadFile = (file) => {
        db.getAttachment(file.doc, file.uid).then((blob) => {
            const f = new File([blob], file.name);
            let url = URL.createObjectURL(f);
            const link = document.createElement("a");
            link.href = url;
            link.download = file.name;
            link.click();
            URL.revokeObjectURL(url);
        });
    }

    if (!value || value.length === 0) return <em>None</em>;
    const firstLink = <Typography.Link type="link" onClick={() => downloadFile(value[0])}>{value[0].name}</Typography.Link>;
    const multiple = value.length > 1;
    if (!multiple) return firstLink;
    return <>{firstLink}, ... ({value.length - 1} more)</>
}

const fetchAttachments = async (value) => {
    return Promise.all(value.map(async (file) => {
        file.blob = await window.db.getAttachment(file.doc, file.uid);
        return file;
    }));
}

export default {
    Editor: ListUpload,
    Viewer,
    defaultValue: [],
    stringify: async () => {
        return 'UNIMPLEMENTED';
    },
    beforePut: (doc, field) => {
        doc[field.id] = doc[field.id].map(({uid, type, name}) => {
            doc._attachments[uid] = { stub: true };
            return {uid, type, name};
        });
    },
    afterGet: (doc, field) => {
        doc[field.id] = doc[field.id].map((file) => {
            file.doc = doc._id;
            return file;
        });
    },
    attachments: {
        getBlob: async (files) => {
            return (await fetchAttachments(files)).map(file => file.blob);
        },
        defaultFileName: '{{item.name}}'
    }
}
